import React from 'react';
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import NavThree from "../components/NavThree";
import Projects from "../components/Projects";

const ProjectsPages = () => {
    return (
        <Layout pageTitle="Kipso | Courses">
            <NavThree active={"projects"}/>
            <PageHeader title="Proyectos"/>
            <Projects/>
            <Footer/>
        </Layout>
    );
};

export default ProjectsPages;
